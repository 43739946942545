import lodashGet from 'lodash/get';

const i18n = {
  en: {
    singin: 'Sing In',
    continue: 'Continue',
    required: 'Required',
    tenantCode: 'Environment identifier',
    noTenant: "Tenant doesn't exist",
    enterYourIndetivier: 'Enter your environment identifier.',
    getDemoAccess: "Don't have an account yet? Get demo access.",
    aboutYou: 'Please enter information about you.',
    name: 'Your Name',
    requestAccess: 'Request demo access',
    emailInvalid: 'Email is not valid',
    email: 'Company Email',
    notes: 'What product and/or service do you require?',
    thanks: 'Thank you',
    thanksDetails: 'We will reach you by email within one working day.',
    privacy: 'Privacy Policy',
    privacyWithDot: 'Privacy Policy.',
    privacyExtend: 'Veengu will collect and use your personal information in accordance with its',
    goBack: 'Go back to veengu.com',
  },
};


export const getLocale = () => {
  const locale = navigator.language.slice(0, 2);
  return i18n[locale] ? locale : 'en';
};

const t = (path) => {
  return lodashGet(i18n, `${getLocale()}.${path}`) || path;
};

export default t;
