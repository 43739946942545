import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import isAlphanumeric from 'validator/es/lib/isAlphanumeric';

import Link from 'components/Link';
import CardLayout from 'components/CardLayout';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import SnackbarNotifications from 'components/SnackbarNotifications';
import { PrivacyComponent } from 'pages/Thanks';

import t from 'i18n';
import { API_URL } from 'env';

const useStyles = makeStyles((theme) => ({
  avatar: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  fullWidth: {
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bottomSpacing1: {
   marginBottom: theme.spacing(1),
  },
  bottomSpacing2: {
   marginBottom: theme.spacing(2),
  },
  bottomSpacing3: {
   marginBottom: theme.spacing(3),
  },
  bottomSpacing4: {
   marginBottom: theme.spacing(4),
  },
}));

const inputMask = (value) => {
  if (value) {
    return value.split('').filter((item) => isAlphanumeric(item) || item === '_' || item === '-').join('');
  } else return '';
}

const TenantRedirectPage = () => {
  const [tenantCode, setTenantCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [apiError, setApiError] = useState('');
  const [isFetching, setFetching] = useState(false);
  const classes = useStyles();

  const handleChange = (e) => {
    const value = e.target.value;
    setErrorMessage('');
    setTenantCode(inputMask(value.slice(0, 20)));
  };

  const handleSingin = (e) => {
    e.preventDefault();
    if (!tenantCode) {
      setErrorMessage(t('required'));
      return;
    }
    setFetching(true);
    fetch(`${API_URL || ''}/tenant/check?code=${tenantCode}`)
    .then(res => {
      const code = res.status;
      if (code === 200) {
        let { hostname } = window.location;
        hostname = hostname === 'localhost' ? 'live.veengu.org' : hostname;
        window.location.href = `https://${tenantCode}.${hostname}`;
      } else if (code === 404) {
        setErrorMessage(t('noTenant'));
      } else {
        throw new Error(res.statusText);
      }
    })
    .catch(err => setApiError(err.message))
    .finally(() => setFetching(false));
  };
  
  return (
    <CardLayout privacyComponent={<PrivacyComponent />}>
      <form className={classes.container} onSubmit={handleSingin} noValidate>
        <Typography variant="h1" className={classes.bottomSpacing2}>Veengu Dashboard</Typography>
        <Typography variant="body2" className={classes.bottomSpacing4}>
          {t('enterYourIndetivier')}
        </Typography>
        <TextInput 
          name="tenant"
          label={t('tenantCode')}
          value={tenantCode}
          onChange={handleChange}
          error={errorMessage}
          helperText={errorMessage}
          required
        />
        <Button loading={isFetching} className={classes.bottomSpacing4}>
          {t('continue')}
        </Button>
        <Link to="/access">
          {t('getDemoAccess')}
        </Link>
      </form>
      <SnackbarNotifications 
        message={apiError}
        isOpen={apiError}
        handleClose={() => setApiError('')}
      />
    </CardLayout>
  );
};

export default TenantRedirectPage;
