import typography from '../typography';

export default {
  root: {
    ...typography.body1,
    padding: '13px 16px',
    borderBottom: 'none',
  },
  head: {
    lineHeight: null,
  },
  body: {
    ...typography.body2,
  },
  sizeSmall: {
    padding: '8px 24px 8px 16px',
  },
};
