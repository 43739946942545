import palette from '../palette';

export default {
  root: {
    borderBottom: `1px solid ${palette.divider}`,
    '&$selected': {
      backgroundColor: palette.background.selection,
    },
    '&$hover': {
      '&:hover': {
        backgroundColor: palette.background.selection,
      },
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  head: {
    '&:last-child': {
      borderBottom: `1px solid ${palette.divider}`,
    },
  },
};
