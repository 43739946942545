import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import isEmail from "validator/es/lib/isEmail";
import { makeStyles } from "@material-ui/styles";

import TextInput from "components/TextInput";
import CardLayout from "components/CardLayout";
import Button from "components/Button";
import Link from "components/Link";
import SnackbarNotifications from "components/SnackbarNotifications";

import t from "i18n";
import { REACT_APP_RE_CAPTCHA_PUBLIC_KEY, API_URL } from "env";

const loadjs = require("loadjs");
loadjs("https://www.google.com/recaptcha/api.js", "cp");

const useStyles = makeStyles((theme) => ({
  avatar: {
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  fullWidth: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  bottomSpacing1: {
    marginBottom: theme.spacing(1),
  },
  bottomSpacing2: {
    marginBottom: theme.spacing(2),
  },
  bottomSpacing3: {
    marginBottom: theme.spacing(3),
  },
  bottomSpacing4: {
    marginBottom: theme.spacing(4),
  },
}));

const PrivacyComponent = () => {
  return (
    <Typography variant="body2">
      {t("privacyExtend")}{" "}
      <Link href="http://veengu.com/privacy-policy/" target="_blank">
        {t("privacyWithDot")}
      </Link>
    </Typography>
  );
};

const initialState = {
  name: "",
  email: "",
  notes: "",
};

const initialErrors = {
  name: "",
  email: "",
};

const isValid = (errors) => !Object.values(errors).filter(Boolean).length;

const Access = (props) => {
  const { history } = props;

  const [errors, setErrors] = useState(initialErrors);
  const [apiError, setApiError] = useState("");

  const [values, setValues] = useState(initialState);
  const handleChange = (e) => {
    const { value, name } = e.target;
    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
    setValues({ ...values, [name]: value });
  };

  const [isFetching, setFetching] = useState(false);

  const handleCaptcha = async () => {
    try {
      await window.grecaptcha.execute();
    } catch (error) {
      console.log(error);
    }
  };

  const validation = () => {
    const errorsCopy = { ...errors };
    if (!values.name) errorsCopy.name = t("required");
    if (!isEmail(values.email)) errorsCopy.email = t("emailInvalid");
    if (!values.email) errorsCopy.email = t("required");
    setErrors(errorsCopy);
    if (!isValid(errorsCopy)) return false;
    return true;
  };
  
  const resetCaptcha = () => {
    window.grecaptcha.reset();
    setRobot(true);
  }

  const handleSumbit = async (e) => {
    e.preventDefault();
    if (!validation()) return;

    const isCaptchaPassed = handleCaptcha();
    if (!isCaptchaPassed) {
      alert("reCAPTCHA not passed!");
      resetCaptcha()
      return;
    }
  };

  const [isRobot, setRobot] = useState(true);

  const send = async () => {
    setFetching(true);
    try {
      const res = await fetch(`${API_URL || ""}/demo/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      if (res.status !== 200) throw new Error(res.statusText);

      history.push("/thanks");
    } catch (error) {
      setApiError(error.message);
    }
    setFetching(false);
  }

  useEffect(() => {
    if (isRobot === false && isFetching === false) {
      send();
    }
  });

  useEffect(() => {
    loadjs.ready("cp", () => {
      window.grecaptcha.ready(() => {
        console.log(window.grecaptcha);
        window.grecaptcha.render("grecaptchaContainer", {
          callback: async (token) => {
            console.log(token);
            setRobot(false);
          },
          'expired-callback': resetCaptcha,
          'error-callback': resetCaptcha,
        });
      });
    });
  }, []);

  const classes = useStyles();

  return (
    <CardLayout privacyComponent={<PrivacyComponent />}>
      <form className={classes.container} onSubmit={handleSumbit} noValidate>
        <Typography variant="h1" className={classes.bottomSpacing2}>
          Veengu Demo Access
        </Typography>
        <Typography variant="body2" className={classes.bottomSpacing4}>
          {t("aboutYou")}
        </Typography>
        <TextInput
          name="name"
          label={t("name")}
          value={values.name}
          onChange={handleChange}
          error={errors.name}
          helperText={errors.name}
          required
        />
        <TextInput
          name="email"
          label={t("email")}
          value={values.email}
          onChange={handleChange}
          error={errors.email}
          helperText={errors.email}
          required
        />
        <TextInput
          name="notes"
          label={t("notes")}
          value={values.notes}
          onChange={handleChange}
          multiline
          rowsMax={4}
        />
        <Button loading={isFetching}>{t("requestAccess")}</Button>
        <div
          id="grecaptchaContainer"
          // data-sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
          data-sitekey={REACT_APP_RE_CAPTCHA_PUBLIC_KEY}
          data-size="invisible"
        ></div>
      </form>
      <SnackbarNotifications
        message={apiError}
        isOpen={apiError}
        handleClose={() => setApiError("")}
      />
    </CardLayout>
  );
};

export default Access;
