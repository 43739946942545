import palette from '../palette';

export default {
  active: {
    fontWeight: 'bold',
    color: palette.primary.main,
    '&>.MuiListItemIcon-root': {
      color: palette.primary.main,
    },
  },
};
