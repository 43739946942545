import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    textDecoration: 'none',
  },
}));

const Link = (props) => {
  const classes = useStyles();
  if (props.href) {
    const { href, children, ...rest } = props;
    return <a className={classes.link} href={href} children={children} {...rest} />;
  } else {
    return <RouterLink className={classes.link} {...props} />;
  }
};

export default Link;
