import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const SnackbarNotifications = (props) => {
  const { message, isOpen, handleClose, severity = 'error', autoHideDuration = 10000 } = props;
  // cache message to prevent message blinking
  const [errMessage, setMessage] = useState(message);
  useEffect(() => {
    if (message) setMessage(message);
  }, [message]);

  return (
    <Snackbar open={!!isOpen} autoHideDuration={autoHideDuration} onClose={handleClose}>
      <MuiAlert elevation={6} variant="filled" severity={severity}>
        {errMessage}
      </MuiAlert>
    </Snackbar>
  );
};

export default SnackbarNotifications;
