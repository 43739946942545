export default {
  content: {
    marginTop: 16,
    marginRight: 24,
    marginBottom: 16,
    marginLeft: 24,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
};
