import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { Router, Switch, Route } from 'react-router-dom';

import history from "./history";
import routes from './routes';
import theme from './theme';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Switch>
          {routes.map((route, ind) => 
            <Route 
              key={ind}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          )}
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
