import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Link from 'components/Link';
import CardLayout from 'components/CardLayout';
import t from 'i18n';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > img': {
      paddingTop: theme.spacing(2),
      maxWidth: 350,
    },
  },
  fullWidth: {
    width: '100%',
  },
  bottomSpacing1: {
    marginBottom: theme.spacing(1),
   },
  bottomSpacing3: {
    marginBottom: theme.spacing(3),
  },
  bottomSpacing4: {
    marginBottom: theme.spacing(3),
  },
  bottomSpacing5: {
    marginBottom: theme.spacing(5),
  },
}));

export const PrivacyComponent = () => {
  return (
    <Link href="http://veengu.com/privacy-policy/" target="_blank">{t('privacy')}</Link>
  )
};

const Thanks = () => {
  const classes = useStyles();

  return (
    <CardLayout privacyComponent={<PrivacyComponent />}>
      <div className={classes.root}>
        <Typography variant="h1" className={classes.bottomSpacing3}>
          {t('thanks')}
        </Typography>
        <Typography variant="body2" align="center" className={classes.bottomSpacing5}>
          {t('thanksDetails')}
        </Typography>
        <img className={classes.bottomSpacing5} src={require('img/thanks.png')} alt="" />
        <Link href="https://veengu.com">
          {t('goBack')}
        </Link>
      </div>
    </CardLayout>
  );
};

export default Thanks;
