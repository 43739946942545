import palette from '../palette';

export default {
  chip: {
    borderRadius: 4,
    margin: '4px 4px 0 0',
    height: '24px',
    color: palette.text.secondary,

    '& .MuiChip-label': {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
};
