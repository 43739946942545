import Home from 'pages/Home';
import Access from 'pages/Access';
import Thanks from 'pages/Thanks';

const routes = [
  {
    path: '/',
    exact: true,
    component: Home
  },
  {
    path: '/access',
    exact: true,
    component: Access
  },
  {
    path: '/thanks',
    exact: true,
    component: Thanks
  },
]

export default routes;