import React from 'react';
import FilledInput from '@material-ui/core/FilledInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  bottomSpacing1: {
    marginBottom: theme.spacing(1),
  },
}));

const TextInput = (props) => {
  const { helperText, label, required, error, ...rest } = props;
  const classes = useStyles();
  return (
    <FormControl className={cx(classes.fullWidth, classes.bottomSpacing1)} error={!!error}>
      <InputLabel required={required} htmlFor={`input-${props.name}`}>{label}</InputLabel>
      <FilledInput
        id={`input-${props.name}`}
        className={classes.fullWidth}
        error={!!error}
        {...rest}
        variant="filled" 
        size="small" 
      />
      <FormHelperText id={`input-${props.name}`}>
        {helperText || '\u0020'}
      </FormHelperText>
    </FormControl>
  )
};

export default TextInput;
