//import { grey } from '@material-ui/core/colors';
//import palette from '../palette';

export default {
  root: {
    //borderBottom: `1px solid ${grey[300]}`,
    //backgroundColor: palette.background.default,
    // textTransform: 'uppercase'
  },
};
