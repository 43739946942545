import React from 'react';
import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
}));

const Button = (props) => {
  const { loading, children, className, ...rest } = props;
  const classes = useStyles();

  return (
    <MuiButton
      className={cx(classes.fullWidth, {[className]: className})}
      variant="contained" 
      color="primary"
      type="submit"
      disabled={loading}
      {...rest}
    >
      {loading ? <CircularProgress style={{ height: 24, width: 24 }} /> : children}
    </MuiButton>
  );
};

export default Button;
